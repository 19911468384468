import { Box, Container, Stack, Typography, Button } from '@mui/material'
import React, { useState } from 'react'
import image1 from '../assets/microsoft.png'
import image2 from '../assets/apple.png'
import image3 from '../assets/linux.png'

const inputData = {
  name: 'so',
  options: [
    {
      label: 'Windows',
      image: image1,
      value: 'windows'
    },
    {
      label: 'MacOS',
      image: image2,
      value: 'mac'
    },
    {
      label: 'Linux',
      image: image3,
      value: 'linux'
    }
  ]
}

const tittleText = '¿Tienes alguna preferencia por una marca o sistema operativo en particular?'

export default function SeventhView({ nextStep }) {
  const [selectedOptions, setSelectedOptions] = useState([])
  console.log(selectedOptions)
  const handleSelect = (value) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    )
  }

  const handleContinue = () => {
    nextStep(inputData.name, selectedOptions)
  }

  return (
    <Container sx={{ flex: 1 }}>
      <Stack
        sx={{ height: '50vh', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={5} display={'flex'}
        spacing={4}
        textAlign={'center'}
      >
        <Typography mx={5} variant='h1'>{tittleText}</Typography>

        <Stack direction="column" width={'50vw'} alignItems={'center'} spacing={1}>
          {inputData.options.map((button) => (
            <Box
              py={2}
              width={'100%'}
              key={button.value}
              onClick={() => handleSelect(button.value)}
              sx={{
                cursor: 'pointer',
                borderRadius: '10px',
                backgroundColor: selectedOptions.includes(button.value) ? 'primary.main' : '#F6F6F6',
              }}
            >
              <img
                src={button.image}
                alt={button.label}
                height={80}
                width={80}
              />
              <Typography
                              color={selectedOptions.includes(button.value) ?  'white': null}

              >{button.label}</Typography>
            </Box>
          ))}
        </Stack>

        <Button
          
        
          sx={{ p: 2, borderRadius: '10px' , my: 2, width: '50vw'}}
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={selectedOptions.length === 0}
        >
          Continuar
        </Button>
      </Stack>
    </Container>
  )
}
