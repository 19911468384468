
import { createTheme } from '@mui/material/styles';
import '@fontsource/montserrat'; // Montserrat font

const MyCustomTheme = createTheme({
    palette: {
        primary: {
            main: '#FE8200'
        },
        secondary: {
            main: '#00B645'
        },
        warning: {
            main: '#FF5645'
        },
        success: {
            main: '#01B738'
        },
        info: {
            main: '#50704E'
        },
        textGray: {
            main: '#6d7285'
        },
        titleGray: {
            main: '#00628C'
        },
        backGray: {
            main: '#f6f7f8c7'
        },

    },
    typography: {

        button: {
            fontFamily: 'Montserrat, Arial, sans-serif', // Fuente específica para botones
            textTransform: 'none', // Si no quieres que el texto del botón esté en mayúsculas
            fontSize: '0.88rem',
            fontWeight:'normal',
        },

        h1: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 'bold',
            fontSize: '1.75rem',
            color: '#25282E',
            font: 'normal normal bold 22px/27px Montserrat'

        },
        h2: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 500,
            fontSize: '1.5rem',
            letterSpacing: '0px',

            color: '#50704E',
        },
        h3: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 500,
            fontSize: '1rem',
            letterSpacing: '0px',

            color: '#50704E',


        },
        h4: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 500,
            fontSize: '0.7rem',
            letterSpacing: '0px',

            color: '#737AE1',
        },
        h6: {
            fontSize: '0.8rem',
        },
        body1: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            lineHeight: '18px',
            color: '#707070',
            fontSize: '0.88rem',
            letterSpacing: '0px',
            font: 'normal normal medium 14px/18px Montserrat',


        },
        body2: {
            fontSize: '1rem',

        }

    },
    components: {
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent', // Fondo transparente
                },
                dot: {
                    backgroundColor: 'red', // Color de los puntos inactivos
                },
                dotActive: {
                    backgroundColor: 'blue', // Color del punto activo
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    color: 'white',
                    fontWeight: 'bold',
                },
                outlined: {
                    color: '#707070',
                },
                text: {
                    color: '#707070',
                    backgroundColor:'#F6F6F6',
                    borderRadius: 10,
                    textAlign:'left',
                },
            },
        },
    },
},

);

export default MyCustomTheme;
