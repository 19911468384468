import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';

const ButtonList = ( {nextStep, inputData} ) => {
    // Lista de botones con sus valores

    return (
        <div>
            <Stack direction="column" width={'100%'} spacing={1} >
                {inputData.options.map((button) => (
                    <Button
                        sx={{
                            height: '5.5vh',
                            justifyContent: 'flex-start',
                        }}
                        
                        fullWidth
                        key={button.value}
                        variant="text"
                        onClick={() => {
                            nextStep( inputData.name, button.value )

                        }}

                    >
                        <Typography
                            variant='body1'
                            fontWeight={500}
                        >
                            {button.label}
                        </Typography>
                    </Button>
                ))}
            </Stack>
        </div>
    );
};
ButtonList.propTypes = {
    nextStep: PropTypes.func.isRequired,
    inputData: PropTypes.shape({
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired
};

export default ButtonList;
