import React, { useState } from 'react';
import InitialView from './views/InitialView';
import SecondView from './views/SecondView';
import ThirdView from './views/ThirdView';
import ProgressBar from './components/ProgressBar';
import { Box, Typography } from '@mui/material';
import logo from './assets/logo.png';
import FourthView from './views/FourthView';
import FifthView from './views/FifthView';
import SixthView from './views/SixthView';
import SeventhView from './views/SeventhView';
import EighthView from './views/EighthView';
import NinethView from './views/NinethView';
import TenthView from './views/TenthView';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const stepsLength = 10;
const initialState = {
  type: "",
  purpose: "",
  aplications: "",
  area: "",
  budget: [0, 0],
  so: "",
  screen: [0, 0],
  lead_name: "",
  lead_email: ""
};

const App = () => {
  const [formData, setFormData] = useState(initialState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showStep, setShowStep] = React.useState(stepsLength - 2);
  const [showActiveStep, setShowActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (formData.type === "desktop") {
      setShowStep(7);
    } else {
      setShowStep(8);
    }
  }, [formData.type]);
  React.useEffect(() => {
    if (activeStep === 8 && formData.type === "desktop") {
      setShowActiveStep(activeStep-1)
    }else{
      setShowActiveStep(activeStep);

    }
  }, [activeStep]);
  
  const handleUpdate = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value
    }));
  };

  function nextStep(field, value) {
    handleUpdate(field, value);

    // Asegurarse de saltar al paso 8 si es un desktop
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 6 && formData.type === "desktop") {
        
        return prevActiveStep + 2; // Saltar al paso 9 directamente si es desktop
      }
      return prevActiveStep + 1;
    });
  }
  function prevStep() {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 8 && formData.type === "desktop") {
        return prevActiveStep - 2; // Retroceder al paso 7 si es desktop
      }
      return prevActiveStep - 1;
    });
  }


  const renderScreen = () => {
    switch (activeStep) {
      case 0:
        return <InitialView nextStep={nextStep} />;
      case 1:
        return <SecondView nextStep={nextStep} />
      case 2:
        return <ThirdView nextStep={nextStep} />;
      case 3:
        return <FourthView nextStep={nextStep} />;
      case 4:
        return <FifthView nextStep={nextStep} />;
      case 5:
        return <SixthView nextStep={nextStep} />;
      case 6:
        return <SeventhView nextStep={nextStep} />;
      case 7:
        return <EighthView nextStep={nextStep} />;
      case 8:
        return <NinethView nextStep={nextStep} handleUpdate={handleUpdate} />;
      case 9:
        return <TenthView formData={formData} />;
      default:
        return setActiveStep(0);
    }
  };

  return (
    <div>
      <nav>
        <Box height={30} my={4} display={'flex'} alignItems="center" position="relative"
          sx={{ mx: { xs: 0, md: 10 } }}
        >

          <Box display="flex"
            flexGrow={1} justifyContent={'center'}>
            {(activeStep > 0 && activeStep < stepsLength - 1)
              && (<ArrowBackIosNewIcon
                aria-label="Go back"
                fontSize='small'
                color='secondary'
                sx={{ cursor: 'pointer' }}
                onClick={prevStep}
              />
              )}
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="center">

            <img src={logo}
              alt="Green Computer"
              width={106}
              height={'auto'}
            />
          </Box>
          <Box display="flex"
            justifyContent={'center'}
            flexGrow={1}>
            {(activeStep > 0 && activeStep < stepsLength - 1)
              && (<Typography color='secondary' fontWeight={'bold'}>
                {showActiveStep}/{showStep}
              </Typography>
              )}
          </Box>

        </Box>
        <Box width={'100%'} display={'flex'} justifyContent={'center'} >
          {activeStep < stepsLength - 1 && (<ProgressBar activeStep={activeStep} />)}
        </Box>

      </nav>

      <Box height={'95%'}>
        {renderScreen()}

      </Box>
    </div>
  );
};

export default App;
