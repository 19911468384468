import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import image from '../assets/aplicaciones.png'

import ToggleButtonsMultiple from '../components/ToggleButtonsMultiple'

const tittleText = '¿Qué aplicaciones o software utilizas frecuentemente?'
const description = 'Elige tantos como quieras'
const inputData =
{
  name: 'aplications',
  options:
    [{
      label: 'Aplicaciones básicas: navegación web, correo electrónico, etc',
      value: 'basics'
    }, {
      label: 'Herramientas de oficina: procesadores de texto, Microsoft Office (Word, Excel), presentaciones)',
      value: 'word_processors'
    },
    {
      label: 'Software de diseño gráfico y multimedia',
      value: 'graphic_design'
    },
    {
      label: 'Aplicaciones de desarrollo de software',
      value: 'development'
    },
    {
      label: 'Aplicaciones para gaming',
      value: 'gaming'
    }]
}


export default function FourthView({ nextStep }) {
  return (
    <Container
      sx={{ flex: 1,
        px: { xs: 2, md: 30 },
       }}
      
    >

      <Stack sx={{ height: '85vh', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={2} display={'flex'}
        textAlign={'center'}
        spacing={1}
      >
        <img
          src={image}
          alt='Apps'
          width={130}
          height={'auto'}
        />
        <Box>
          <Typography variant='h1' >{tittleText} </Typography>
          <Typography mt={1} variant='body1'  >{description} </Typography>
        </Box>
        <ToggleButtonsMultiple inputData = {inputData} nextStep = {nextStep} />
      
      </Stack>

    </Container>
  )
}
