import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import { Box } from '@mui/material';


export default function ProgressBar({ activeStep }) {


  return (
    
    <Box sx={{ width: '90%',px: { xs: 2, md: 50 }, }}>
      <MobileStepper
        variant="progress"
        steps={9}
        position="static"
        activeStep={activeStep}
        sx={{ '& .MuiLinearProgress-root': { width: '100%' } }}

        LinearProgressProps={{
          color: 'secondary',
        }}
        nextButton={null} // set as null these buttons
        backButton={null}
      />
    </Box>
  );
}
/*   nextButton={
        <Button size="small" color='secondary' variant="contained" onClick={handleNext} disabled={activeStep === 5}>
          Next
          
        </Button>
        
      }
      backButton={
        <Button  color='primary' variant="contained"  onClick={handleBack} disabled={activeStep === 0}>
        
          Back
        </Button>
      }*/