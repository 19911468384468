import { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

export default function Component({ inputData , nextStep }) {

  const [selectedButtons, setSelectedButtons] = useState(new Array(inputData.options.length).fill(false));

  const handleButtonClick = (index) => {
    setSelectedButtons(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const getSelectedOptions = () => {
    const selectedOptions = inputData.options.filter((_, index) => selectedButtons[index])
    .map(option => option.value)
    return selectedOptions;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">


      <Grid container spacing={2} justifyContent="center">
        {inputData.options.map((option, index) => (
          <Grid key={index + 'option'} size={12}>
            <Button fullWidth
              sx={{
                bgcolor: selectedButtons[index] ? 'primary.main' : null,
                height: '8vh',
                justifyContent: 'flex-start',
              }}
              onClick={() => handleButtonClick(index)}
            >
              <Typography
                mx={1}
                variant='body1'
                color={selectedButtons[index] ? 'white' : null}
                fontWeight={500}
              > 
              {option.label}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>

      <Button fullWidth
          sx={{ p: 2, borderRadius: '10px' , my: 2}}
          disabled={getSelectedOptions().length === 0}
          variant="contained" onClick={() => nextStep(inputData.name, getSelectedOptions() )}>Continuar
        </Button>
    </Box>
    
  );
}
/*<Typography variant='h6' color='textGray' mt={2}>
        {getSelectedOptions().join(', ')}
      </Typography>*/