import { Container, Stack, Typography } from '@mui/material'
import React from 'react'
import CardCustom from '../components/CardCustom'
import image1 from '../assets/personal.png'
import image2 from '../assets/estudios.png'
import image3 from '../assets/profesional.png'
import image4 from '../assets/gaming.png'
import Grid from '@mui/material/Grid2';

const tittleText = '¿Cuál es el propósito principal del equipo?'

const purposeOptions =
{
  name: 'purpose',
  options:
    [{
      name: 'Uso personal',
      image: image1,
      value: 'personal'
    }, {
      name: 'Estudios',
      image: image2,
      value: 'studies'
    }, {
      name: 'Trabajo profesional',
      image: image3,
      value: 'work'
    }, {
      name: 'Gaming',
      image: image4,
      value: 'gaming'
    }]
}
export default function ThirdView({ nextStep }) {
  return (
    <Container
      sx={{ flex: 1 }}
    >

      <Stack sx={{ height: '85vh', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={5} display={'flex'}
        textAlign={'center'}
        spacing={5}
      >
        <Typography variant='h1' >{tittleText} </Typography>

        <Grid container spacing={2}>
          {purposeOptions.options.map((option) => {
            return (
              <Grid size={6} key={ option.value }>
                <CardCustom
                  image={option.image}
                  nextStep={() => nextStep(purposeOptions.name, option.value)}
                  title={option.name}
                  height={100}
                />
              </Grid>
            )
          }
          )}
        </Grid>
      </Stack>

    </Container>
  )
}
