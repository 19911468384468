import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import image from '../assets/campoArea.png'

import ButtonList from '../components/ButtonList'

// 	area: 'computer_science'|| 'graphic_design' ||'engineering' || 'business_administration' || 'social_sciences' || 'medicine' || 'education'


const inputData =
{
  name: 'area',
  options:
    [{
      label: 'Ciencias de la computación/tecnología',
      value: 'computer_science'
    },
    {
      label: 'Diseño gráfico/multimedia',
      value: 'graphic_design'
    },
    {
      label: 'Ingeniería',
      value: 'engineering'
    },
    {
      label: 'Administración de empresas',
      value: 'business_administration'
    },
    {
      label: 'Ciencias sociales',
      value: 'social_sciences'
    },
    {
      label: 'Medicina',
      value: 'medicine'
    },
    {
      label: 'Educación',
      value: 'education'
    },
    {
      label: 'Otros',
      value: 'other'
    }
    
  ]
}



const tittleText = '¿En qué área o campo trabajas o estudias?'
export default function FifthView({ nextStep }) {
  return (
    <Container
      sx={{ flex: 1,
        px: { xs: 2, md: 30 },
       }}
    >

      <Stack sx={{ height: '85vh',  justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={2} display={'flex'}
        textAlign={'center'}
        spacing={1}
      >
        <img
          src={image}
          alt='Green Computer'
          width={160}
          height={'auto'}
        />
        <Box>
          <Typography variant='h1' >{tittleText} </Typography>
        </Box>
        <Box width={'100%'}>
        <ButtonList nextStep = {nextStep} inputData = {inputData} />
        </Box>
      </Stack>

    </Container>
  )
}
