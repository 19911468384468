import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { Box } from '@mui/material';

export default function CardCustom({ image, nextStep, height, width, title }) {
    return (
        <Card sx={{ maxWidth: 345, width:"100%", height:'100%', bgcolor: '#F6F6F6', borderRadius: '10px', boxShadow: 'none' }}>
            <CardActionArea onClick={nextStep} sx={{width:'100%', px: 2, pt: 2 }}>
                <CardMedia
                    component="img"
                    height={height ?? 250}
                    width={width ?? 150}
                    sx={{
                        borderRadius: '10px',
                        objectFit: 'contain',  // Ajusta cómo se muestra la imagen
                    }}
                    image={image}
                    alt={title ?? 'ITEM'}
                />
                <CardContent sx={{py: 0}}>
                    <Box m={0} display={'flex'} justifyContent={'center'}>
                        <Typography sx={{p: 0}} gutterBottom variant="h3" component="div">
                            {title ?? 'ITEM'}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
