import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import image from '../assets/tamaño.png'

import ButtonList from '../components/ButtonList'
const inputData = {
  name: 'screen',
  options:
    [{
      label: '13-15 pulgadas',
      value: [13, 15]
    },
    {
      label: '16 o más pulgadas',
      value: [16, 100]
    }
    ]
}

const tittleText = '¿Qué tamaño de pantalla prefieres?'

export default function EighthView({ nextStep , handleUpdate }) {
  return (
    <Container
      sx={{ flex: 1, px: { xs: 2, md: 30 } }}
    >
      <Stack sx={{ height: '85vh', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={4} display={'flex'}
        textAlign={'center'}
        spacing={4}
      >
        <img
          src={image}
          alt='Green Computer'
          width={160}
          height={'auto'}
        />
        <Box>
          <Typography variant='h1' >{tittleText} </Typography>
        </Box>
        <Box width={'100%'}>
        <ButtonList nextStep = {nextStep} inputData = {inputData} />
        </Box>

      </Stack>

    </Container>
  )
}
