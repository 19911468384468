import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import image from '../assets/presupuesto.png'

import ButtonList from '../components/ButtonList'

const inputData =
{
  name: 'budget',
  options:
    [{
      label: 'Hasta $750.000',
      value: [0, 750000]
    },
    {
      label: '$750.000 - $1.000.000',
      value: [750000, 1000000]
    },
    {
      label: 'Más de $1.000.000',
      value: [1000000, 100000000]
    }

    ]
}

const tittleText = '¿Cuál es tu presupuesto aproximado para la compra del equipo?'

export default function SixthView({ nextStep }) {
  return (
    <Container
      sx={{
        flex: 1,
        px: { xs: 2, md: 30 },


      }}
    >
      <Stack sx={{  justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={4} display={'flex'}
        textAlign={'center'}
        spacing={2}
      >
        <img
          src={image}
          alt='Green Computer'
          width={160}
          height={'auto'}
        />
        <Box>
          <Typography variant='h1' >{tittleText} </Typography>
        </Box>
        <Box width={'100%'}>
          <ButtonList nextStep={nextStep} inputData={inputData} />
        </Box>

      </Stack>

    </Container>
  )
}
