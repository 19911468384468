import { Container, Stack, Typography } from '@mui/material'
import React from 'react'
import CardCustom from '../components/CardCustom'
import image1 from '../assets/laptop.jpeg'
import image2 from '../assets/desktop.jpeg'

import Grid from '@mui/material/Grid2';

const tittleText = '¿Qué tipo de equipo necesitas?'
const inputData =
{
  name: 'type',
  options:
    [{
      name: 'Laptop',
      image: image1,
      value: 'laptop'
    }, {
      name: 'Desktop',
      image: image2,
      value: 'desktop'
    }]
}
export default function SecondView({ nextStep, handleUpdate }) {
  return (
    <Container
      sx={{ flex: 1 }}
    >

      <Stack sx={{ height: '50vh', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', display: 'flex', gap: 2 }}
        mx={3} mt={10} display={'flex'}
        spacing={4}
        textAlign={'center'}
      >
        <Typography mx={5} variant='h1' >{tittleText} </Typography>


        <Grid  container spacing={2}>

          {inputData.options.map((option) => {
            return (
              <Grid  size={6} key={option.value}>
                <CardCustom
                  image={option.image}
                  nextStep={() => nextStep(inputData.name, option.value)}
                  title={option.name}
                  height={'auto'}
                />
              </Grid>
            )
          }
          )}

        </Grid>

      </Stack>

    </Container>
  )
}
